<template>
    <v-card class="elevation-0 alert-note-card" v-if="ready">
        <v-card-text class="px-0 secondary--text pt-2 pb-0">
            <template v-if="note.type == 1">
                <p class="subtitle-1 lh-14 mb-3 mt-1">{{ name }} has provided the following medical information:</p>
                <v-card class="elevation-2 mb-3 lh-12">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Current Medications</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <p class="body-2 secondary--text nl2br lh-12 mb-0">
                            Allegra - 10mg Daily<br>
                            Women's Multivitamin - 2x Daily
                        </p>
                    </v-card-text>
                </v-card>
                <v-card class="elevation-2 mb-3">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Medication Allergies</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <p class="body-2 secondary--text nl2br lh-12 mb-0">
                            Allergic to Latex!<br>
                            No allergies to medications or anesthesia.
                        </p>
                    </v-card-text>
                </v-card>
                <v-card class="elevation-2 mb-3">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Current Health Conditions</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <p class="body-2 secondary--text nl2br lh-12 mb-0" v-html="content.conditionsDesc || 'No information provided.'"></p>
                    </v-card-text>
                </v-card>
                <v-card class="elevation-2 mb-3">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Care Providers</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <p class="body-2 secondary--text lh-12 nl2br mb-0">
                            Dr. Gregory House<br>
                            Primary Care Provider<br>
                            Princeton Plainsboro Hospital<br>
                            Princeton, NJ<br>
                            https://wuori.com <br>
                            888-333-3333 <br>
                            michael.wuori@gmail.com
                        </p>
                    </v-card-text>
                </v-card>
                <v-card class="elevation-2 mb-0">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Other Medical Information</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <p class="body-2 secondary--text nl2br lh-12 mb-0">
                            My health directives are filed with our family attorney.
                        </p>
                    </v-card-text>
                </v-card>
            </template>
            <template v-if="note.type == 2">
                <p class="subtitle-1 lh-14 mb-3 mt-1">{{ name }} has a dependent that needs care:</p>
                <v-card class="elevation-2 mb-2">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">John Smith <span class="subtitle-1 pl-2 font-weight-normal">(age 14)</span></v-card-title>
                    <v-divider />
                    <v-card-text class="secondary--text lh-12">
                        <p class="body-2 mt-0 mb-2">
                            <b>Temporary Care Provider:</b><br>
                            <span class="nl2br">
                                My parents should take care of John in my absence.
                            </span>
                        </p>
                        <p class="body-2 mb-2">
                            <b>Daily Care Instructions:</b><br>
                            <span class="nl2br">
                                John is old enough to be left alone (not overnight) but needs to be reminded to bathe and brush his teeth!
                            </span>
                        </p>
                        <p class="body-2 mb-2">
                            <b>School/Nursery or Daily Schedules:</b><br>
                            <span class="nl2br">
                                John attends Roosevelt High School<br>
                                He takes the bus to and from.
                            </span>
                        </p>
                        <p class="body-2 mb-2">
                            <b>Medical Conditions:</b><br>
                            <span class="nl2br">
                                No information provided.
                            </span>
                        </p>
                        <p class="body-2 mb-0">
                            <b>Additional Care Information:</b><br>
                            <span class="nl2br">
                                Our family attorney has additional information regarding John's care.
                            </span>
                        </p>
                    </v-card-text>
                </v-card>
            </template>
            <template v-if="note.type == 3">
                <p class="subtitle-1 lh-14 mb-3 mt-1">{{ name }} has a pet that needs care:</p>
                <v-card class="elevation-2 mb-2">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Tucker <span class="subtitle-1 pl-2 font-weight-normal">(dog)</span></v-card-title>
                    <v-divider />
                    <v-card-text class="secondary--text lh-12">
                        <p class="body-2 mt-0 mb-2">
                            <b>Feeding Instructions:</b><br>
                            Feed 1 cup &bull; 2x daily.
                            <br>
                            Food type/brand: Anything grain-free.
                            <br>
                            Food is located: In Laundry Room
                        </p>
                        <p class="body-2 mb-2">
                            <b>Potty Routine:</b><br>
                            <span class="nl2br">
                                Usually goes out 2-3x daily, but will let you know when he needs to!
                            </span>
                        </p>
                        <p class="body-2 mb-2">
                            <b>Toys &amp; Accessories:</b><br>
                            <span class="nl2br">
                                Leash and everything else is by front door.
                            </span>
                        </p>
                        <p class="body-2 mb-2">
                            <b>Medical/Care Providers:</b><br>
                            <span class="nl2br">
                                Boarding: Crazy K9's <br>
                                (555) 555-5555 <br>
                                Vet: Short Animal Hospital <br>
                                (555) 555-5555
                            </span>
                        </p>
                    </v-card-text>
                </v-card>
            </template>
            <template v-if="note.type == 4">
                <p class="subtitle-1 lh-14 mb-3 mt-1">{{ name }} has provided the following information for accessing and caring for their property:</p>
                <v-card class="elevation-2 mb-3 lh-12">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Property Address</v-card-title>
                    <v-divider />
                    <v-card-text>
                        <p class="body-2 secondary--text mb-0">
                            42 Wallaby Way<br>
                            Charlotte, NC 28203<br>
                            <v-btn small outlined color="primary" class="mt-1" href="https://www.google.com/maps/place/charlotte nc 28203" target="_blank">
                               <v-icon small class="mr-2">fa fa-map-marked-alt</v-icon> Get Directions
                            </v-btn>
                        </p>
                    </v-card-text>
                </v-card>
                <v-card class="elevation-2 mb-3">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Accessing the Property</v-card-title>
                    <v-divider />
                    <v-card-text class="secondary--text lh-12">
                        <p class="body-2 mb-2">
                            <b>Access Instructions:</b><br>
                            <span class="nl2br">There is a key in the lockbox attached to back porch.</span>
                        </p>
                        <p class="body-2 mb-0">
                            <b>Entering the Property:</b><br>
                            <span class="nl2br">
                                You must disarm the security system by entering the code 445289 on the panel near the front door. 
                            </span>
                        </p>
                    </v-card-text>
                </v-card>
                <v-card class="elevation-2 mb-3">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Caring for the Property</v-card-title>
                    <v-divider />
                    <v-card-text class="secondary--text lh-12">
                        <p class="body-2 mb-2">
                            <b>Caring for items inside the property:</b><br>
                            <span class="nl2br">
                                Please water the plants in both the living room and bedroom.
                            </span>
                        </p>
                        <p class="body-2 mb-2">
                            <b>Caring for items outside the property:</b><br>
                            <span class="nl2br">
                                If waste bins are on street please pull back to house so I don't get fined!
                            </span>
                        </p>
                        <p class="body-2 mb-0">
                            <b>Securing the property:</b><br>
                            <span class="nl2br">
                                Leave living room light on when leaving, please and be sure to active the security system by pressing the green button on the panel as you walk out.
                            </span>
                        </p>
                    </v-card-text>
                </v-card>
                <v-card class="elevation-2 mb-3" v-if="content.extra">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">Additional Property Instructions</v-card-title>
                    <v-divider />
                    <v-card-text lh-12>
                        <p class="body-2 secondary--text nl2br mb-0" v-html="content.extra || 'No information provided.'"></p>
                    </v-card-text>
                </v-card>
            </template>
            <template v-if="note.type == 5">
                <p class="subtitle-1 lh-14 mb-3 mt-1">{{ name }} has left an additional note for you:</p>
                <v-card class="elevation-2 mb-3">
                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1">
                        Contact my Employer
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                        <p class="body-2 secondary--text nl2br lh-12 mb-0">
                            If I've suffered major injury or will otherwise be away for a long time, please contact my workplace to let them know:<br><br>
                            Acme Financial<br>
                            Manager: Harry August<br>
                            harry.august@acmefinancial.com
                        </p>
                    </v-card-text>
                </v-card>
            </template>
        </v-card-text>
    </v-card>
</template>
<script>
    
export default {
    name: 'AlertNote',
    props: {
        note: {
            required: true,
            type: Object,
        },
        name: {
            required: true,
            type: String
        }
    },
    components: {
    },
    computed: {
        content(){
            return JSON.parse(this.note.body);
        }
    },
    data: () => ({
        // content: '',
        ready: false
    }),
    methods: {
        initData(){
            // need to wait for expansion panel to create/open
            setTimeout(() => {
                this.ready = true;
            }, 100);
            setTimeout(() => {
                this.handleLinks();
            }, 1500);
        },
        handleLinks(){
            let notes = document.querySelectorAll('.alert-note-card .v-card__text .nl2br:not(.linked)');
            notes.forEach(note => {
                note.innerHTML = this.linkify(note.innerHTML);
                note.classList.add('linked');
            });
        },
        onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
        },
        linkify(input){

            if(input.includes('href')){
                return input;
            }

            let text = input;
            let links = [];
            let linksFound = text.match( /(?:www|https?)[^\s]+/g ) || [];
            let emailsFound = text.match( /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim ) || [];
            let phonesFound = text.match( /([+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6})/gim ) || [];
            let aLink = [];
            links = [].concat(linksFound, emailsFound, phonesFound);

            if ( links.length ) {

                links = links.filter(this.onlyUnique);

                for ( let i=0; i<links.length; i++ ) {
                    let replace = links[i];
                    if(links[i].includes('http')){
                        if ( !( links[i].match( /(http(s?)):\/\// ) ) ) { replace = 'http://' + links[i] }
                        aLink.push( '<a href="' + replace + '" target="_blank">' + replace + '</a>' );
                        text = text.split( links[i] ).join( aLink[i] );
                    }else if(links[i].includes('@')){
                        aLink.push( '<a href="mailto:' + replace + '" target="_blank">' + replace + '</a>' );
                        text = text.split( links[i] ).join( aLink[i] );
                    }else{
                        aLink.push( '<a href="tel:' + replace + '" target="_blank">' + replace + '</a>' );
                        text = text.split( links[i] ).join( aLink[i] );
                    }
                }
                return text;

            }
            else {
                return input;
            }
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>